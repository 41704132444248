import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO/seo'
import SliceZone from '../components/SliceZone'

export default ({ data }) => {
  const doc = data.prismic.allAbout_pages.edges[0]
  const slices = doc.node.body

  return (
    <>
      <SEO title='About' />
      <SliceZone slices={slices} />
    </>
  )
}

export const query = graphql`
query aboutQuery($lang: String) {
  prismic {
    allAbout_pages(lang: $lang) {
      edges {
        node {
          page_title
          _meta {
            lang
            type
            uid
          }
          body {
            ... on PRISMIC_About_pageBodyImage_with_text {
              type
              label
              primary {
                image
                text
              }
            }
            ... on PRISMIC_About_pageBodyVideo_with_text {
              type
              label
              primary {
                text
                video
              }
            }
            ... on PRISMIC_About_pageBodyCentered_image {
              type
              label
              primary {
                image
                imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 899) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
